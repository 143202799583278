/* src/index.css */

/* Tailwind base/styles */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom global styles */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Body background, font, etc. */
body {
  background-color: #f3f4f6; /* Tailwind's gray-100 */
  font-family: 'Inter', sans-serif;
}

/* Deactivate entire page scrolling; phone container is pinned */
html, body {
  overscroll-behavior: none;
  overflow: hidden; /* Prevent the entire page from scrolling */
  background: #000; /* or your chosen background color */
}

/* Button styling based on active/inactive states */
.button-active {
  background-color: var(--main-color);
  color: #FFFFFF;
}

.button-inactive {
  background-color: #f3f4f6; /* Gray-100 */
  color: #d1d5db;            /* Gray-300 */
  cursor: not-allowed;
}

/* Placeholder styling */
input::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
input:focus::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
